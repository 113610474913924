import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";
import Form from "react-bootstrap/Form";
import TelegramIcon from "../assets/svg/TelegramIcon";
import WhatsappIcon from "../assets/svg/WhatsappIcon";
import EyeIcon from "../assets/svg/EyeIcon";
import { useNavigate } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import GamesIcon from "../assets/svg/GamesIcon";
import HomeIcon from "../assets/svg/HomeIcon";
// import RiExchangeFill from "../assets/svg/ProfileIcon";
import Exchange from "../assets/images/Exchange.png";
import SportsIcon from "../assets/svg/SportsIcon";
import CashierIcon from "../assets/svg/CashierIcon";
import logo from "../assets/svg/logo_new.png";
import BotHeadIcon1 from "../assets/images/games-icon/In-play.png";
import BotHeadIcon2 from "../assets/images/games-icon/Cricket.png";
import BotHeadIcon3 from "../assets/images/games-icon/Soccer.png";
import BotHeadIcon4 from "../assets/images/games-icon/Aviator.png";
import BotHeadIcon5 from "../assets/images/games-icon/dragon-tiger1.png";
import BotHeadIcon6 from "../assets/images/games-icon/Teen-patti.png";
import BotHeadIcon7 from "../assets/images/games-icon/roulette.png";
import SportsIcon1 from "../assets/images/games-icon/Exchange.png";
import HomeIcon1 from "../assets/images/games-icon/home-icon1.png";
import EzugiIcon from "../assets/images/games-icon/Ezugi.png";
import SlotsnIcon from "../assets/images/games-icon/slots.png";
import CashierIcon1 from "../assets/images/games-icon/cashier.png";
import { useSelector } from "react-redux";
import Phone from "../assets/images/phone-call.png";
import BonusIcon from "../assets/images/icons/gift.png";
import ReferralIcon from "../assets/images/icons/referral.png";
import CrazyTime from "../assets/images/games-icon/crazytime.png";

import EvolutionIcon from "../assets/images/games-icon/Evolution.png";
import VivoIcon from "../assets/images/games-icon/VivoGaming.png";
import SupernowaIcon from "../assets/images/games-icon/Supernowa.png";
import SlotsIcon from "../assets/images/games-icon/slots.png";
import { AiOutlineClose } from "react-icons/ai";
import { getNotifications } from "../redux/app/actions";
import kingmaker from "../assets/images/icons/king.png";

const Header = () => {
  //test
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const { appDetails } = useSelector((state) => state.app);
  const [websiteNotifications, setWebsiteNotifications] = useState([]);
  const [showNotifications, setShowNotifications] = useState(true);
  const loadNotifications = async () => {
    // get notifications
    const notifications = await getNotifications();
    if (notifications && notifications?.data?.data) {
      setShowNotifications(true);
      setWebsiteNotifications(notifications?.data?.data);
    }
  };
  useEffect(() => {
    loadNotifications();
  }, []);
  return (
    <>
      {(websiteNotifications.length && showNotifications && (
        <div className="marqu">
          <marquee>
            {websiteNotifications.map((notf, idx) => (
              <label style={{ marginRight: "30px" }}>{notf?.content}</label>
            ))}
          </marquee>
          <span onClick={() => setShowNotifications(false)}>
            <AiOutlineClose />
          </span>
        </div>
      )) ||
        null}
      <header className="header beforeheader">
        <div className="top_head">
          <Container fluid>
            <Row>
              <Col xs={12}>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="logo">
                    <a href="/">
                      <img src={appDetails?.LOGO_URL} alt="Logo" />
                    </a>
                    <div className="contact-social prime-num d-none d-md-block">
                      <div className="phone-num-new">
                        <span>
                          <img src={Phone} />
                          {appDetails?.PHONE}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="middle-part">
                    <Navbar expand="lg">
                      <Container>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                          <Nav className="me-auto">
                            <Nav.Link href="/">
                              {/* <HomeIcon /> */}
                              <img src={HomeIcon1} />
                              Home
                            </Nav.Link>
                            <Nav.Link href="/sports" className="exchange-nav">
                              <div>
                                {/* <SportsIcon /> */}
                                <img src={SportsIcon1} />
                              </div>
                              Sports
                            </Nav.Link>
                            {/* <Nav.Link href="/sports">
                          <img src={Exchange} alt="Exchange Icon" width={20} />{" "}
                          Exchange
                        </Nav.Link> */}
                            <Nav.Link href="/sign-in">
                              {/* <GamesIcon />  */}
                              <img src={EzugiIcon} />
                              Ezugi
                            </Nav.Link>
                            <Nav.Link href="/sign-in">
                              <img src={EvolutionIcon} /> Evolution
                            </Nav.Link>
                            <Nav.Link href="/sign-in">
                              <img src={VivoIcon} />
                              Vivo
                            </Nav.Link>
                            <Nav.Link href="/sign-in">
                              <img src={SupernowaIcon} />
                              Supernowa
                            </Nav.Link>
                            <Nav.Link href="/sign-in">
                              {/* <GamesIcon />  */}
                              <img src={SlotsnIcon} />
                              Slots
                            </Nav.Link>

                            {/* <Nav.Link href={CasinoProviders["worldcasino"]?.href}>
                      <GamesIcon />
                      World Casino
                    </Nav.Link> */}

                            <Nav.Link href="/sign-in">
                              {/* <CashierIcon /> */}
                              <img src={CashierIcon1} />
                              Cashier
                            </Nav.Link>
                            <Nav.Link href="/sign-in">
                              {/* <CashierIcon /> */}
                              <img src={BonusIcon} />
                              Bonus
                            </Nav.Link>
                            <Nav.Link href="/sign-in">
                              {/* <CashierIcon /> */}
                              <img src={ReferralIcon} />
                              Referral
                            </Nav.Link>
                          </Nav>
                        </Navbar.Collapse>
                      </Container>
                    </Navbar>
                  </div>
                  <div className="headerRight">
                    <ul className="social">
                      <li>
                        <a
                          href={
                            appDetails?.TELEGRAM != ""
                              ? appDetails?.TELEGRAM
                              : "#"
                          }
                          target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
                        >
                          <TelegramIcon />
                        </a>
                      </li>
                    </ul>
                    <Button
                      variant="primary"
                      onClick={() => {
                        navigate("/sign-in");
                      }}
                      className="button-primary btn_signin"
                    >
                      SIGN IN
                    </Button>
                    <Button
                      variant="primary"
                      onClick={() => {
                        navigate("/mobile-number");
                      }}
                      className="button-primary btn_signup ms-2"
                    >
                      SIGN UP
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="bottom_head">
          <Container fluid>
            <Row>
              <Col xs={12} className="px-0">
                <ul>
                  {/* <li>
                  <a href="/mobile-number">
                    <img src={BotHeadIcon1} alt="bottom head link icon" />
                    <span>Inplay</span>
                  </a>
                </li>*/}
                  <li>
                    <a href="/mobile-number">
                      <img src={BotHeadIcon2} alt="bottom head link icon" />
                      <span>Cricket</span>
                    </a>
                  </li>

                  <li>
                    <a href="/mobile-number">
                      <img src={BotHeadIcon5} alt="bottom head link icon" />
                      <span>DragonTiger</span>
                    </a>
                  </li>
                  <li>
                    <a href="/mobile-number">
                      <img src={BotHeadIcon7} alt="bottom head link icon" />
                      <span>Roulette</span>
                    </a>
                  </li>
                  <li>
                    <a href="/mobile-number">
                      <img src={BotHeadIcon6} alt="bottom head link icon" />
                      <span>TeenPatti</span>
                    </a>
                  </li>
                  <li>
                    <a href="/mobile-number">
                      <img src={CrazyTime} alt="bottom head link icon" />
                      <span>Hilo</span>
                    </a>
                  </li>
                  {/* <li>
                  <a href="/mobile-number">
                    <img src={BotHeadIcon3} alt="bottom head link icon" />
                    <span>Soccer</span>
                  </a>
                </li> */}
                  <li>
                    <a href="/mobile-number">
                      <img src={BotHeadIcon4} alt="bottom head link icon" />
                      <span>Aviator</span>
                    </a>
                  </li>
                  <li>
                    <a href="/mobile-number">
                      <img src={kingmaker} alt="bottom head link icon" />
                      <span>Kingmaker</span>
                    </a>
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </div>

        {/* <Offcanvas
        className={"steps-canvas"}
        show={show}
        onHide={handleClose}
        placement={"end"}
      >
        <Offcanvas.Header closeButton="closeButton" closeVariant="white">
          <Offcanvas.Title>
            <span className="top-heading">Sign in now!</span>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="form-steps">
            <h2 className="h2">Hello Again!</h2>
            <p>
              Welcome back, you've
              <br />
              been missed!
            </p>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicName">
                <Form.Control type="text" placeholder="Enter Username*" />
              </Form.Group>
              <Form.Group
                className="mb-3 position-relative"
                controlId="formBasicPassword"
              >
                <Form.Control type="password" placeholder="Password*" />
                <span className="icon">
                  <EyeIcon />
                </span>
              </Form.Group>
              <div className="d-flex justify-content-end mb-3">
                <a className="form-link" href="#">
                  Password Recovery
                </a>
              </div>
              <Button variant="primary" type="submit">
                Sign in
              </Button>
            </Form>
            <div className="sign-up mt-4">
              Not a member?
              <br />
              <a href="register">Sign Up Now</a>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas> */}
      </header>
    </>
  );
};

export default Header;
